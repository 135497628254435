import React from 'react';
import {CBusqueda} from '../components/CBusqueda';

export const HomeView = () => {
    return (
        <div>
            <CBusqueda />
        </div>
    )
}
